//import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const getVodUrl = (vcode) => {
  return ajax.get(`${base}/api/itedu/v1/doge-cloud/vod-url?vcode=${vcode}`);
};

export const dogeVodApi = {
  // import时得花括号明确
  getVodUrl: getVodUrl,
};
